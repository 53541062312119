<template>
	<loader v-if="loading" />
	<page v-else>
		<div class="xdashboard-tile-wrapper" style="margin-bottom: 1.5%">
			<div style="display: flex; margin-top: 1.5%">
				<div class="xdashboard-tile">
					<h3>Loans By School</h3>
					<table class="xrecentlyviewed">
						<tr
							v-for="(item, index) in Object.entries(
								statistics.totalApplicationsByType
							).sort()"
							:key="index">
							<td style="width: 40px">
								<div class="xusericon" data-size="small">
									<div
										class="xusericon-picture"
										style="background: transparent"></div>
								</div>
							</td>
							<td>
								<div style="float: right">{{ item[1] }}</div>
								<div>{{ functions.displayProductType(item[0]) }}</div>
							</td>
						</tr>
					</table>
				</div>
				<div class="xdashboard-tile">
					<h3>Loans By Lender</h3>
					<table class="xrecentlyviewed">
						<tr
							v-for="(item, index) in Object.entries(
								statistics.totalApplicationsByLender
							).sort()"
							:key="index">
							<td style="width: 40px">
								<div class="xusericon" data-size="small">
									<div
										class="xusericon-picture"
										style="background: transparent"></div>
								</div>
							</td>
							<td>
								<div style="float: right">{{ item[1] }}</div>
								<div>{{ functions.getLenderName(item[0]) }}</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</page>
</template>

<script setup>
	import { onMounted, reactive } from 'vue';
	import store from '@/store';
	import page from '@/views/layout/Page.vue';
	import loader from '@/components/Loader.vue';
	import { Chart } from 'chart.js';
	import axios from 'axios';
	import { useRouter, useRoute } from 'vue-router';
	import functions from '@/use/functions.js';

	var route = useRoute();
	var router = useRouter();
	var applicationData = $ref(null);
	var loanData = $ref(null);
	var loading = $ref(true);

	var $ = reactive({
		recentlyViewed: [],
	});

	var statistics = reactive({
		statusType: {},
		applicationsByMonth: {},
		totalApplications: false,
		totalApplicationsByLender: {},
		totalApplicationsByType: {},
		totalLoans: false,
		totalLoanAmount: false,
		totalLoansByLender: false,
	});

	async function getApplications() {
		await axios(`/api/applications/dashboard/search`, {
			method: 'POST',
			require: 'json',
		})
			.then((response) => {
				var data = response.data;

				// make sure the applications data is not empty
				if (data.length > 0 && Array.isArray(data)) {
					// get the toal number of applications
					statistics.totalApplications = data.length;

					var currentMonth = new Date().getMonth() + 2;
					var currentYear = new Date().getFullYear();

					for (let i = 1; i < 7; i++) {
						var thisMonth = currentMonth - i;
						var thisYear = currentYear;

						// if the month value is 0 or negative
						if (thisMonth < 1) {
							// add 12 months to the value
							thisMonth = thisMonth + 12;

							// subtract 1 from the year
							thisYear = currentYear - 1;
						}

						thisMonth = ('0' + thisMonth).slice(-2);

						statistics.applicationsByMonth[`${thisYear}-${thisMonth}`] = 0;
					}

					// for each of the applications
					data.forEach((application) => {
						application.applicants.forEach((applicant) => {
							// if this is the primary borrower
							if (applicant.applicantTypeId === 1) {
								// set the full name of the borrower (put the two names in an array, filter out the null names, and join with a space)
								application.name =
									[applicant.firstName, applicant.lastName]
										.filter((n) => n)
										.join(' ') || 'No Name';

								// set the initials of the borrower
								application.initials = `${
									applicant.firstName?.charAt(0) || ''
								}${applicant.lastName?.charAt(0) || ''}`.trim();
							} else if (applicant.applicantTypeId === 2) {
								application.nameCoApplicant = applicant.firstName
									? `${applicant.firstName} ${applicant.lastName}`
									: 'No Name';
							}
						});

						statistics.statusType[application.statusName] =
							(statistics.statusType[application.statusName] || 0) + 1;

						// get the year-month of this application
						var yearMonth = application.createdAtUtc.substr(0, 7);

						if (statistics.applicationsByMonth[yearMonth] !== undefined) {
							statistics.applicationsByMonth[yearMonth] =
								(statistics.applicationsByMonth[yearMonth] || 0) + 1;
						}

						statistics.totalApplicationsByLender[application.lenderId] =
							(statistics.totalApplicationsByLender[application.lenderId] ||
								0) + 1;
						statistics.totalApplicationsByType[application.productId] =
							(statistics.totalApplicationsByType[application.productId] || 0) +
							1;

						// if the lenders api has already loaded
						if (store.state.types['Lenders']) {
							// for each of the lenders that matches the current application's lenderId
							store.state.types['Lenders']
								.filter((lender) => lender.id == application.lenderId)
								.forEach((lender) => {
									// set the application lenderName
									application.lenderName = lender.name;
								});
						}
					});
				}

				applicationData = data;
			})
			.catch((error) => {
				applicationData = 'Error Loading Results';
				console.log(`API Error: /api/applications/dashboard/search/`, error);
			});
	}

	async function getDrawsBySchool() {
		await axios(`/api/loanservice/draws/schools`, {
			method: 'POST',
			require: 'json',
			data: {
				DrawStatusId: 2,
			},
		})
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				applicationData = 'Error Loading Results';
				console.log(`API Error: /api/loanservice/draws/schools`, error);
			});
	}

	async function getLoans() {
		await axios(`/api/loanservice/loans/filter`, {
			method: 'POST',
			require: 'json',
			data: {},
		})
			.then((response) => {
				var data = response.data;

				// make sure the loans data is not empty
				if (data) {
					// get the toal number of loans
					statistics.totalLoans = data.totalCount;

					statistics.totalLoanAmount = 0;

					// for each of the loans
					data.items.forEach((item) => {
						statistics.totalLoanAmount += item.loanAmount;
					});
				}

				loanData = data;
			})
			.catch((error) => {
				loanData = 'Error Loading Results';
				console.log(`API Error: /api/loanservice/loans/filter/`, error);
			});
	}

	async function getRecentlyViewed() {
		// if the localstorage has any recently viewed loans
		if (localStorage.getItem('cache.RecentlyViewedPages')) {
			// retreive the data
			var recentLoans = JSON.parse(
				localStorage.getItem('cache.RecentlyViewedPages')
			);

			// append to recentlyViewed
			$.recentlyViewed = $.recentlyViewed.concat(recentLoans);
		}

		$.recentlyViewed = $.recentlyViewed.sort((a, b) => {
			return b.timestamp - a.timestamp;
		});
	}

	onMounted(async (e) => {
		router.push({ path: '/certifications' });

		loading = false;

		getDrawsBySchool();

		functions.getLenders();
		functions.getType('Products');
		getApplications();
		getLoans();
		getRecentlyViewed();

		setTimeout(function () {
			new Chart(document.getElementById('line-chart'), {
				type: 'line',
				data: {
					labels: Object.keys(statistics.applicationsByMonth).reverse(),
					datasets: [
						{
							label: 'Applications',
							data: Object.values(statistics.applicationsByMonth).reverse(),
							borderColor: function (context) {
								const chart = context.chart;
								const { ctx, chartArea } = chart;

								if (!chartArea) {
									// this case happens on initial chart load
									return;
								}

								var gradient;

								gradient = ctx.createLinearGradient(700, 0, 0, 0);
								gradient.addColorStop(0, '#5600ff');
								gradient.addColorStop(1, '#5600ff');

								return gradient;
							},
							fill: true,
							backgroundColor: function (context) {
								const chart = context.chart;
								const { ctx, chartArea } = chart;

								if (!chartArea) {
									// this case happens on initial chart load
									return;
								}

								var gradient;

								gradient = ctx.createLinearGradient(0, 450, 0, 150);
								gradient.addColorStop(0, 'transparent');
								gradient.addColorStop(1, 'transparent');

								return gradient;
							},
							borderWidth: 5,
							hoverBorderWidth: 6,
							pointHitRadius: 50,
							pointRadius: 10,
							pointBorderColor: 'transparent',
							pointBackgroundColor: 'transparent',
							pointHoverBorderColor: 'white',
							pointHoverBackgroundColor: '#5600ff',
							pointHoverBorderWidth: 4,
							pointHoverRadius: 13,
							tension: 0,
						},
					],
				},
				options: {
					responsive: true,
					legend: {
						display: false,
					},
					plugins: {
						legend: {
							position: 'top',
						},
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									color: '#dddddd',
								},
							},
						],
						yAxes: [
							{
								gridLines: {
									color: '#dddddd',
								},
								ticks: {
									precision: 0,
									beginAtZero: true,
								},
							},
						],
					},
				},
			});

			new Chart(document.getElementById('doughnut-chart'), {
				type: 'doughnut',
				data: {
					labels: Object.keys(statistics.statusType),
					datasets: [
						{
							label: 'My First Dataset',
							data: Object.values(statistics.statusType),
							backgroundColor: ['#875ec0', '#eb4885', '#feb62a'],
							height: '300px',
							borderWidth: 0,
						},
					],
				},
				options: {
					cutoutPercentage: 75,
					legend: {
						display: true,
						position: 'bottom',
					},
				},
			});
		}, 500);
	});
</script>

<style lang="scss" scoped></style>
