export const oidcSettings = {
    responseType: "id_token token",
    scope: "openid profile",
    automaticSilentRenew: true,
    automaticSilentSignin: true,
};

export enum EnvType {
    local = "LOCAL",
    localci = "LOCALCI",
    ci = "CI",
    qa = "QA",
    uat = "UAT",
    prod = "PROD",
}

export interface OidcSettings {
    env: EnvType;
    authority: string;
    clientId: string;
    redirectUri: string;
    documents: string;
    googleAnalyticsId: string;
}

export interface OidcEnvironments {
    local: OidcSettings;
    localci: OidcSettings;
    ci: OidcSettings;
    qa: OidcSettings;
    uat: OidcSettings;
    prod: OidcSettings;
}

export const oidcEnvironments: OidcEnvironments = {
    local: {
        env: EnvType.local,
        authority: "https://localhost:44351",
        clientId: "school-portal",
        redirectUri: "https://localhost:44371/oidc-callback",
        documents: "https://localhost:44666",
        googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
    },
    localci: {
        env: EnvType.localci,
        authority: "https://identity-dev.studentchoice.org",
        clientId: "school-portal",
        redirectUri: "https://localhost:44344/oidc-callback",
        documents: "https://app-lz02-ci-docupl-eus.azurewebsites.net",
        googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
    },
    ci: {
        env: EnvType.ci,
        authority: "https://identity-dev.studentchoice.org",
        clientId: "school-portal",
        redirectUri: "https://app-lz02-ci-schptl-eus.azurewebsites.net/oidc-callback",
        documents: "https://app-lz02-ci-docupl-eus.azurewebsites.net",
        googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
    },
    qa: {
        env: EnvType.qa,
        authority: "https://identity-qa.studentchoice.org",
        clientId: "school-portal",
        redirectUri: "https://app-lz02-qa-schptl-eus.azurewebsites.net/oidc-callback",
        documents: "https://app-lz02-qa-docupl-eus.azurewebsites.net",
        googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD,
    },
    uat: {
        env: EnvType.uat,
        authority: "https://identity-uat.studentchoice.org",
        clientId: "school-portal",
        redirectUri: "https://app-lz02-uat-schptl-eus.azurewebsites.net/oidc-callback",
        documents: "https://upload.uat.opalsystems.org",
        googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
    },
    prod: {
        env: EnvType.prod,
        authority: "https://identity.studentchoice.org",
        clientId: "school-portal",
        redirectUri: "https://app-lz02-prd-schptl-eus.azurewebsites.net/oidc-callback",
        documents: "https://upload.opalsystems.org",
        googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD,
    },
};

export function getEnvironment(): OidcSettings {
    // default to PROD
    let environment = oidcEnvironments.prod;
    const host = window.location.hostname;
    const port = window.location.port;

    if (host.includes("localhost")) {
        environment = port === "44344" ? oidcEnvironments.localci : oidcEnvironments.local;
    } else if (host.includes("-ci")) {
        environment = oidcEnvironments.ci;
    } else if (host.includes("-qa")) {
        environment = oidcEnvironments.qa;
    } else if (host.includes("-uat") || host.includes(".uat.")) {
        environment = oidcEnvironments.uat;
    }

    // disable logging for UAT and PROD environments
    if (environment.env === EnvType.uat || environment.env === EnvType.prod) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        console.log = () => { };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        console.warn = () => { };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        console.debug = () => { };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        console.info = () => { };
    }

    return environment;
}