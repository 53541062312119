import "../public/css/responsive-tables.css";
import "../public/css/css.css";

import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { reactive } from "vue";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import moment from "moment-timezone";
moment.updateLocale("en", { invalidDate: "" });

// add a request interceptor to all axios requests
axios.interceptors.request.use(
	(config: any) => {
		// add the authorization to the request headers
		config.headers[
			"Authorization"
		] = `Bearer ${store.getters["oidcStore/oidcAccessToken"]}`;

		// check if the request requires json
		if (config.require === "json") {
			// set the responseType to 'json' and set the 'content-type' and 'accept' headers
			config.responseType = "json";
			config.headers["Content-Type"] = "application/json";
			config.headers["Accept"] = "application/json";

			// add a transformResponse function to the config
			config.transformResponse = function (data: any) {
				// try to parse the response data as json
				try {
					return JSON.parse(data);
				} catch (error) {
					// if the response data is not valid json, throw an error
					throw new Error(`The response from the server is not valid JSON.`);
				}
			};
		}

		return config;
	},
	(error: any) => Promise.reject(error)
);

const app = createApp(App);

const $store = reactive({
	mobileMenu: false,
});

app.config.globalProperties.$moment = moment;
app.config.globalProperties.localTimezone = moment.tz.guess();
app.config.globalProperties.$animate = { disrespectUserMotionPreference: true };

app.provide("vueStore", $store);
app.provide("gtag", app.config.globalProperties.$gtag);
app.use(store);
app.use(router);
app.use(autoAnimatePlugin);
app.mount("#app");
