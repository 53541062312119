<template>
	<router-view />
</template>

<script>
	import { defineComponent } from 'vue';
	import { mapGetters, mapState } from 'vuex';

	export default defineComponent({
		props: [],
		computed: {
			...mapState(['idleVue']),
			...mapGetters('oidcStore', ['oidcIsAuthenticated']),
		},
		methods: {
			checkVersionNumber() {
				//////////////////////////////////////////////////////////////////
				// this function can be used to clear user's localStorage cache //
				// when necessary by incrementing the latestVersion variable    //
				//////////////////////////////////////////////////////////////////

				// increase this variable to clear the user's localStorage cache
				var latestVersion = 6;

				// gets the users' current version number
				var userVersion = localStorage.getItem('cache.VersionNumber');

				// if the latest version of the application is higher than the users current version
				if (latestVersion > Number(userVersion)) {
					// loop through each of the entries in localStorage
					Object.entries(localStorage).forEach(([key]) => {
						// find all entries which start with "cache."
						if (key.startsWith('cache.')) {
							// remove from localStorage
							localStorage.removeItem(key);
						}
					});

					// set the version number in localStorage to the latest version
					localStorage.setItem(`cache.VersionNumber`, latestVersion);
				}
			},
		},
		watch: {
			$route: {
				immediate: true,
				handler(to) {
					this.checkVersionNumber();
					document.title = to.meta.title
						? `${to.meta.title} - School Portal`
						: 'School Portal';
				},
			},
		},
	});
</script>
